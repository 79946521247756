import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "../style"; // Ensure this path matches your actual styles file location

const Stats = () => {
  const [isInView, setIsInView] = useState(false);
  const [waitlistStats, setWaitlistStats] = useState(() => {
    // Attempt to load cached stats from localStorage
    const savedStats = localStorage.getItem("waitlistStats");
    return savedStats ? JSON.parse(savedStats) : { totalWaitlistCount: 0, sumMonthlyVolume: 0 };
  });
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, { root: null, rootMargin: "0px", threshold: 0.5 });

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stats`);
        setWaitlistStats({
          totalWaitlistCount: response.data.totalWaitlistCount,
          sumMonthlyVolume: response.data.sumMonthlyVolume,
        });
        // Cache the fetched stats in localStorage
        localStorage.setItem("waitlistStats", JSON.stringify({
          totalWaitlistCount: response.data.totalWaitlistCount,
          sumMonthlyVolume: response.data.sumMonthlyVolume,
        }));
        // Update the timestamp of the last fetch
        localStorage.setItem("lastUpdate", Date.now().toString());
      } catch (error) {
        console.error('Error fetching waitlist stats:', error);
      }
    };

    // Check the timestamp of the last update
    const lastUpdate = localStorage.getItem("lastUpdate");
    const now = Date.now();

    if (!lastUpdate || now - parseInt(lastUpdate, 10) > 3600000) { // 3600000 ms = 1 hour
      fetchStats();
    }
  }, []);

  const stats = [
    {
      id: "stats-1",
      title: "Users Waitlisted",
      value: waitlistStats.totalWaitlistCount + "+",
      target: waitlistStats.totalWaitlistCount,
      increase: 5, // Adjust or remove as needed
    },
    {
      id: "stats-2",
      title: "Expected Volume",
      value: `$${(waitlistStats.sumMonthlyVolume * 12) / 1000}K+`,
      target: (waitlistStats.sumMonthlyVolume * 12) / 1000,
      increase: 10, // Adjust or remove as needed
    },
  ];

  return (
    <section ref={statsRef} className={`${styles.flexCenter} flex-row flex-wrap sm:mb-20 mb-6`}>
      {stats.map((stat) => (
        <StatCounter key={stat.id} stat={stat} isInView={isInView} />
      ))}
    </section>
  );
};

const StatCounter = ({ stat, isInView }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isInView) {
      const animateCounter = async () => {
        const { target, increase } = stat;
        const duration = 50;
        let currentCount = 0;

        while (currentCount < target) {
          currentCount += increase;
          if (currentCount > target) {
            currentCount = target;
          }
          setCount(currentCount);
          await new Promise((resolve) => setTimeout(resolve, duration));
        }
      };

      animateCounter();
    } else {
      setCount(0); // Reset count when not in view
    }
  }, [isInView, stat]);

  const formatValue = (value) => {
    if (stat.id === "stats-1") {
      return value + "+";
    } else if (stat.id === "stats-2") {
      return `$${value}K+`;
    }
    return value;
  };

  return (
    <div className={`flex-1 flex justify-start items-center flex-row m-3 text-center ${styles.flexCenter}`}>
      <h4 className="font-poppins font-semibold text-2xl lg:text-5xl text-white whitespace-nowrap overflow-hidden">
        {isInView ? formatValue(count) : 0}
      </h4>
      <p className={`font-poppins font-normal text-xl lg:text-3xl md:text-2xl stat-title text-orange-400 uppercase ml-3 whitespace-nowrap`}>
        {stat.title}
      </p>
    </div>
  );
};

export default Stats;
