import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WaitlistForm from "./pages/WaitlistForm";
import Home from "./pages/Home"; // Import the Home component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} /> {/* Set Home as the index route */}
        <Route path="waitlistform" element={<WaitlistForm />} />
      </Routes>
    </Router>
  );
};

export default App;
