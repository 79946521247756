import React from "react";


const Button = ({ styles }) => {
  return (
  <button type="button" className={`py-4 px-6 font-poppins font-medium text-[18px] text-white bg-navy-gradient rounded-[10px] outline-none ${styles}`}>
    Waitlist Now
  </button>
);
  }
export default Button;