import React, { useState } from "react";
import backgroundVideo from '../assets/bg.mp4';
import ReferralModal from "./ReferralModal"; // Update the import statement

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State variable to track modal visibility

  // Function to toggle the modal's visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <section
      id="home"
      className="relative flex md:flex-row flex-col"
      style={{
        height: "100vh",
      }}
    >
      <video className="absolute w-full h-full object-cover opacity-70" playsInline autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="relative flex-1 flex flex-col justify-center items-start p-6 md:p-16">
        <h1 className="font-poppins font-semibold text-3xl md:text-5xl lg:text-7xl text-white leading-tight">
          <span style={{ color: "rgba(12, 11, 52, 1)" }}>PAYMENT3</span>
          <br />
          <span className="text-white">provides a payment bridge </span>
          <br />
          <span className="text-white">between traditional finance and</span>
          <br />
          <span style={{ color: "rgba(12, 11, 52, 1)" }}>Web3.</span>
        </h1>
        <div className="flex justify-center"> {/* Center the button horizontally */}
        <button
          className="bg-orange-500 text-white py-3 px-6 rounded-[10px] mt-4 hover:bg-orange-600 cursor-pointer"
          onClick={toggleModal}
        >
          Waitlist Referral Program
        </button>
      </div>
    </div>
      {isModalOpen && <ReferralModal toggleModal={toggleModal} />} {/* Use ReferralModal instead of Modal */}
    </section>
  );
}

export default Hero;
