import React from "react";
import styles from "../style";
import { Navbar, Hero, Footer, Stats, CTA, FAQ, AboutPayment3,} from "../components";

function Home() {

    return (
        <div className="text-white bg-main-bg dark:bg-main-dark-bg">
            {/* Navbar */}
            <div className={`${styles.flexStart}`}>
              <Navbar />
            </div>
            <Hero />
            <div className="bg-primary">
            <div className={`${styles.paddingX} ${styles.flexStart} pt-10`}>
                    <div style={{ marginTop: "50px" }}> {/* Add margin-top here */}
                        <Stats />
                    </div>
                </div>
            <div className={`${styles.paddingX} ${styles.flexStart} pt-10`}>
                        <AboutPayment3 />
                </div>

                <div className={`${styles.paddingX} ${styles.flexStart} pt-10`}>
                    <FAQ />
                </div>
                <div className={`${styles.paddingX} ${styles.flexStart} pt-10`}>
                    <CTA />
                </div>
                
                <div className={`${styles.paddingX} ${styles.flexStart} pt-10`}>
                    <Footer />
                </div>              
            </div>
        </div>
    );
}

export default Home;
