import styles from "../style";
import { Payment } from "../assets";
import { socialMedia } from "../constants";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`} style={{ width: '100%' }}>
      <div className={`${styles.flexCenter} text-white md:flex-row flex-col mb-8 w-full`} style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 'var(--max-container-width)' }}>
        <div className="text-center"> {/* Center the content */}
          <Link to='/'>
            <button>
              <img
                src={Payment}
                className="w-[266px] h-[72.14px] object-contain"
              />
            </button>
          </Link>
          <div className="mt-4"> {/* Add margin to separate logo and text */}
            <p className={`${styles.paragraph} max-w-full`}>
              Payment3 provides a payment bridge between traditional finance and Web3.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]" style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 'var(--max-container-width)' }}>
        <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
          Payment3. All Rights Reserved 2024.
        </p>

        <div className="flex flex-row md:mt-0 mt-6">
          {socialMedia.map((social, index) => (
            <img
              key={social.id}
              src={social.icon}
              alt={social.id}
              className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
              }`}
              onClick={() => window.open(social.link)}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Footer;



