import { createContext, useReducer, useEffect, useState } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
    const [isLogin, setIslogin] = useState(false);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(state.user));

        if (JSON.parse(localStorage.getItem("user")) === null) {
            setIslogin(false);
        } else {
            setIslogin(true);
        }
    }, [state.user]);

    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                isLogin: isLogin,
                dispatch,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
