import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { root } from "postcss";

import { AuthContextProvider } from "./context/AuthContext";

ReactDOM.render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>,
    document.getElementById("root")
);
