import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Payment } from "../assets";
import Button from "./Button";

const Navbar = () => {
  const [buttonSize, setButtonSize] = useState("md"); // Default size
  const [hasScrolled, setHasScrolled] = useState(false);

  // Function to update button size based on screen width
  const updateButtonSize = () => {
    if (window.innerWidth < 768) {
      setButtonSize("sm"); // Small size for mobile
    } else {
      setButtonSize("md"); // Medium size for larger screens
    }
  };

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  // Add event listeners to update button size and handle scroll
  useEffect(() => {
    updateButtonSize();
    window.addEventListener("resize", updateButtonSize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", updateButtonSize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Style for the fixed Navbar
  const fixedNavbarStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1000, // Adjust as needed
    backgroundColor: hasScrolled ? "rgba(12, 11, 52, 1)" : "transparent", // Set background color conditionally
    transition: "background-color 0.3s ease", // Add smooth transition
  };

  // CSS style to set a fixed color for the Payment logo (white)
  const paymentLogoStyle = {
    filter: "invert(0)", // Set a fixed color here (0 for white, 1 for black)
    width: buttonSize === "sm" ? "150px" : "210px", // Reduce logo width for mobile
    height: buttonSize === "sm" ? "38px" : "52px", // Reduce logo height for mobile
  };

  // CSS style to set a fixed size for the button (smaller for mobile)
  const buttonStyle = {
    fontSize: buttonSize === "sm" ? "14px" : "16px", // Reduce button font size for mobile
  };

  return (
    <nav className="w-full flex py-6 justify-between items-center navbar p-4" style={fixedNavbarStyle}>
      <Link to="/"> {/* Wrap the logo with a Link to the home page */}
        <img src={Payment} alt="Payment3" className="w-[210px] h-[52px]" style={paymentLogoStyle} />
      </Link>
      <div className="pl-5">
        <Link to="/waitlistform">
          <Button size={buttonSize} style={buttonStyle}>Waitlist Now</Button>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
