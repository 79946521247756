import React from "react";
import Button from "./Button";

const ReferralModal = ({ toggleModal }) => {
  const modalStyle = {
    backgroundColor: "rgba(12, 11, 52, 1)",
    maxWidth: "400px", // Set the maximum width to 400px
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div style={modalStyle} className="p-8 rounded-lg shadow-lg text-white">
        <h2 className="text-2xl font-semibold mb-4">Referral Program Details</h2>
        <p>
          Join our Waitlist and Earn Rewards!
          <br />
          Be part of our waitlist and receive a unique referral code.
          <br />
          For every user who signs up using your referral code, you'll earn 25 basis points (bps) of their transaction volume for the first year.
        </p>
        <p className="mt-4">
          <strong>Terms and Conditions Apply:</strong> Please note that this referral program is subject to terms and conditions. Make sure to review the complete program details upon signup. Terms are subject to change at anytime.
        </p>
        <button
          className="bg-orange-500 text-white py-2 px-4 rounded-[10px] mt-4 hover:bg-orange-600 cursor-pointer"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ReferralModal;
