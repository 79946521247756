import React from 'react';
import ComplianceIcon from '../assets/ComplianceIcon.png';
import DeFiIcon from '../assets/DeFiIcon.png';
import ConversionIcon from '../assets/ConversionIcon.png';
import FinalStepIcon from '../assets/FinalStepIcon.png';

const StepCard = ({ step, description, iconSrc }) => {
    // Inline styles
    const styles = {
        card: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '0.5em',
            margin: '1em 0',
        },
        iconContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Changed from 'flex-start' to 'center' for center alignment
            background: 'linear-gradient(144.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #221a81 91.61%)',
            width: '100%',
            padding: '1em',
            borderTopLeftRadius: '0.5em',
            borderTopRightRadius: '0.5em',
            flexDirection: 'row', // Change flexDirection to column for vertical stacking on mobile
        },
        icon: {
            width: '60px',
            height: '60px',
            objectFit: 'cover',
            marginBottom: '0.5em', // Adjust margin to separate icon from title when stacked
            marginRight: '0.5em'

        },
        title: {
            color: 'white', // White text color
            fontSize: '1.2rem', // Adjust as needed
            fontWeight: 'bold',
            textAlign: 'center', // Ensure text is centered, especially useful when flexDirection is column
        },
        content: {
            padding: '1em',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        },
        description: {
            color: 'rgba(12, 11, 52, 1)', // Text color in rgba format
            fontFamily: 'font-poppins', // Use the same font family as the rest of the section
            fontSize: '0.875rem', // Adjust as needed
        },
    };

    return (
        <div className="step-card" style={styles.card}>
            <div className="icon-container" style={styles.iconContainer}>
                <img
                    src={iconSrc}
                    alt={`${step} icon`}
                    style={styles.icon}
                />
                <h4 style={styles.title}>{step}</h4> {/* Title inside icon container */}
            </div>
            <div className="content" style={styles.content}>
                <p className="font-poppins font-normal text-md text-gray-900 mt-2">{description}</p>
            </div>
        </div>
    );
};



const AboutPayment3 = () => {
    const steps = [
        { title: "Compliance Made Easy", description: "We handle the complexity of compliance for you. With automated KYC/AML checks, we ensure that your transactions are secure and compliant, needing verification only before your first transaction.", iconSrc: ComplianceIcon },
        { title: "DeFi at Work", description: "Choose the token you’d like to pay with and we’ll convert it directly to a stablecoin. This process is designed for minimal volatility and maximum reliability.", iconSrc: DeFiIcon },
        { title: "Seamless Conversion", description: "The stablecoin is then sent to our offramp and your cryptocurrency is converted instantly into fiat currency.", iconSrc: ConversionIcon },
        { title: "Remain Bankless", description: "The traditional bank account linked with our system will make payments on your behalf, ensuring that your payments are made on time, every time.", iconSrc: FinalStepIcon },
    ];

    return (
        <section className="py-12">
            <div className="container mx-auto px-4">
                <div className="text-left">
                    <h2 className="text-3xl font-poppins font-semibold text-white mb-6">About Payment3</h2>
            <p className="font-poppins font-normal text-base md:text-lg lg:text-xl text-white text opacity-90 leading-normal mb-4">
                At Payment3, we are redefining the way you interact with Web3. We understand that the world of digital currency is evolving, and we are here to bridge the gap between the flexibility of non-custodial wallets and the stability of traditional finance.
            </p>

            <h3 className="text-3xl font-poppins font-semibold text-white mb-6">
                Our Mission
            </h3>
            <p className="font-poppins font-normal text-base md:text-lg lg:text-xl text-white text opacity-90 leading-normal mb-4">
                Payment3 aims to provide a straightforward, efficient, and cost-effective way for you to use your non-custodial wallets for everyday transactions. We are dedicated to bringing the power of blockchain technology to your fingertips, ensuring a seamless integration of your digital assets into your daily life.
            </p>
            </div>
            <h4 className="text-3xl font-poppins font-semibold text-white mt-4">
                How it Works
            </h4>
            <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
                    {steps.map((step, index) => (
                        <StepCard
                            key={index}
                            step={step.title}
                            description={step.description}
                            iconSrc={step.iconSrc}
                        />
                    ))}
                </div>
                <h3 className="mt-6 text-3xl font-poppins font-semibold text-white mb-6">
                    Who is Payment3 For?
                </h3>
                <p className="font-poppins font-normal text-base md:text-lg lg:text-xl text-white text opacity-90 leading-normal mb-4">
                Payment3 is ideal for individuals who want to conveniently make direct payments from self custody wallets or move their funds across borders in a very cost-effective and effecient manner. Our platform was created to streamline the use of crypto assets to pay for everyday expenses, like rent. This is a solution tailor-made for those who are looking to for ways to maintain and grow their digital assets, while still navigating the expenses of daily life.                </p>
                <p className="font-poppins font-normal text-base md:text-lg lg:text-xl text-white text opacity-90 leading-normal mb-4">
                    Our platform also serves as a lifeline for those who face challenges with traditional banking, especially in the US and Canada. International students and individuals who receive financial support from abroad often grapple with the complexities of currency conversion, international wire fees, and the hurdle of setting up a bank account in a foreign country. Payment3 eliminates these obstacles by allowing direct transactions in cryptocurrency. This not only bypasses the need for a traditional bank account but also cuts down on international transfer and foreign exchange fees. It's a straightforward, efficient solution for those needing to make payments in a currency they don't hold, ensuring that funds reach their destination swiftly and securely.
                </p>

            </div>
        </section>
    );
}

export default AboutPayment3;
