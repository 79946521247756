import React, { useState } from "react";
import styles from "../style";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`mb-4 ${styles.fullWidth}`}>
      <button onClick={toggleFAQ} className={`flex items-center justify-between w-full bg-primary p-4 rounded-lg`}>
      <h3 className={`text-xl font-medium text-white`} style={{ textAlign: 'left' }}>{question}</h3>        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-6 w-6 text-white ${isOpen ? "rotate-180" : "rotate-0"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
          />
        </svg>
      </button>
      {isOpen && (
        <div className={`bg-contrast p-4 rounded-lg mt-2 ${styles.fullWidth}`}>
          <p className="text-white">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "Why use Payment3?",
      answer: "One of the complicated parts of blockchain technology is interacting with the off-chain world. If you need a simple solution that does NOT require you to jump through 4 hoops before being able to interact with the traditional financial ecosystem, Payment3 is for you. We aim to provide a service where you can easily access the whole tradFi world with just your non-custodial wallet.",    },
    {
      question: "How much does it cost?",
      answer: "Payment3 charges a 2% fee for helping you bridge the world of DeFi and TradFi.",
    },
    {
      question: "Do I have to KYC?",
      answer: "Yes, in order to use the Payment3 platform, you do need to go through a KYC check. At Payment3, we aim to be leaders in our compliance with all KYC/AML regulations for our customers.",
    },
    {
      question: "What other bills can I pay?",
      answer: "We are actively building out other solutions for the seamless integration of Web3 with your everyday life. We are starting with rent because everyone needs a place to live. Feel free to contact us at info@payment3.network and let us know about your payment needs, we will gladly investigate if this a solution we can offer you!",
    },
    {
      question: "How long does the transaction take?",
      answer: "As soon as we receive your crypto, we convert it to fiat and initiate the payment. The time it takes for the payment to be processed depends on the recipient's bank. Typically, it takes 1-3 business days. We are working on reducing this time to a matter of minutes, so stay tuned for updates!",
    },
    {
      question: "What chains do you support?",
      answer: "Ethereum, Polygon and Solana.",
    },
    {
      question: "Where is Payment3 available?",
      answer: "Payment3 is available to make payments in the United States and Canada. We are working on expanding our services to more regions in the near future.",
    },{
      question: "Do I have to be a US or Canadian citizen to use Payment3?",
      answer: "No, however, you do need to provide proof of residency in Canada or the US to KYC.",
    }
    // Add more FAQ items as needed
  ];

  return (
    <section className={`bg-primary ${styles.paddingX} max-w-2xl mx-auto`}>
      <h2 className="text-3xl font-semibold text-white mb-6">Frequently Asked Questions</h2>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </section>
  );
};

export default FAQ;
