import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import styles from "../style";
import { Footer } from "../components";
import backgroundVideo from "../assets/bg.mp4";

Modal.setAppElement("#root");  // Important for screen readers

const WaitlistForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [estimatedMonthlyVolume, setEstimatedMonthlyVolume] = useState("");
  const [kycRequired, setKycRequired] = useState(false);
  const [referredBy, setReferredBy] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for managing the error message
  const [buttonText, setButtonText] = useState("Join Waitlist"); // New state for button text
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state to disable button

  // Function to fetch referral code from the backend
  const fetchReferralCode = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/waitlist/referral-code?email=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        console.log('Referral Code:', data.referralCode);
        setReferralCode(data.referralCode); // Set the referral code in the state
      }
    } catch (error) {
      console.error('Error fetching referral code:', error);
    }
  };

  const animateButtonText = () => {
    let dotCount = 0;
    const intervalId = setInterval(() => {
      dotCount = (dotCount % 3) + 1; // Cycle through 1 to 3
      setButtonText(`Joining Waitlist${'.'.repeat(dotCount)}`); // Update button text with dots
    }, 500); // Change dots every 500ms

    return () => clearInterval(intervalId); // Cleanup interval on unmount or when form is submitted
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message on new submission
    setIsButtonDisabled(true); // Disable the button
    const stopAnimation = animateButtonText(); // Start animating the button text

    const formData = {
      name,
      email,
      estimatedMonthlyVolume,
      kycRequired,
      referredBy,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      stopAnimation(); // Stop animating the button text

      if (response.status === 200) {
        console.log(response.status);
        setIsFormSubmitted(true);
        fetchReferralCode(email);
      } else {
        const errorData = await response.json(); // Assuming the server responds with JSON
        // Handle different error statuses
        switch (response.status) {
          case 400:
            setErrorMessage(errorData.message || "Invalid or non-existent email"); // Use the server's error message if available
            setIsButtonDisabled(false); // Re-enable the button for correction and resubmission
            setButtonText("Join Waitlist"); // Reset button text
            break;
          case 409:
            setErrorMessage("Email already registered.");
            setIsButtonDisabled(false); // Re-enable the button for correction and resubmission
            setButtonText("Join Waitlist"); // Reset button text
            break;
          case 500:
            setErrorMessage("Something went wrong, please try again.");
            setIsButtonDisabled(false); // Re-enable the button for correction and resubmission
            setButtonText("Join Waitlist"); // Reset button text
            break;
          default:
            setErrorMessage("An unexpected error occurred. Please try again.");
            setIsButtonDisabled(false); // Re-enable the button for correction and resubmission
            setButtonText("Join Waitlist"); // Reset button text
        }
      }
    } catch (error) {
      // console.error('Network error:', error);
      setErrorMessage("Network error: Please check your internet connection and try again.");
      setIsButtonDisabled(false); // Re-enable the button for correction and resubmission
      setButtonText("Join Waitlist"); // Reset button text
    }
  };


  return (
    <div>
    <div className="relative bg-main-bg w-full h-screen flex-col">
      <video className="absolute object-cover w-full h-full opacity-70" playsInline autoPlay loop muted >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="absolute w-full flex flex-col align-start justify-end p-8 md:top-10 top-0 left-0 z-30">

      {!isFormSubmitted ? (
        <form className="w-full mx-auto bg-gray-900 p-4 md:p-8 rounded-lg mb-12 max-w-sm md:max-w-md lg:max-w-lg xl:max-w-[400px]" onSubmit={handleSubmit}>
        <h2 className="text-3xl text-white font-bold text-center">
            Join the Waitlist
          </h2>
          {/* Error message displayed here, right before the form starts */}
        {errorMessage && (
          <p className="text-red-500 text-center my-2">{errorMessage}</p> // Displaying the error message in red
        )}
          {/* Name Input */}
          <div className="mb-4">
            <label className="text-gray-400 font-semibold">Name: </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="text-white rounded-lg bg-gray-700 mt-2 p-2 pl-4 pr-8 focus:border-blue-500 focus:bg-gray-800 focus:outline-none w-full"
            />
          </div>

          {/* Email Input */}
          <div className="mb-4">
            <label className="text-gray-400 font-semibold">Email: </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-white rounded-lg bg-gray-700 mt-2 p-2 pl-4 pr-8 focus:border-blue-500 focus:bg-gray-800 focus:outline-none w-full"
            />
          </div>

          {/* Estimated Monthly Volume Input */}
          <div className="mb-4">
            <label className="text-gray-400 font-semibold">Estimated Monthly Volume in ($): </label>
            <input
              type="text"
              value={estimatedMonthlyVolume}
              onChange={(e) => setEstimatedMonthlyVolume(e.target.value)}
              className="text-white rounded-lg bg-gray-700 mt-2 p-2 pl-4 pr-8 focus:border-blue-500 focus:bg-gray-800 focus:outline-none w-full"
            />
          </div>

          {/* KYC Required Toggle */}
          <div className="mb-4">
            <label className="text-gray-400 font-semibold">Would you use the platform if required to KYC? </label>
            <div className="flex mt-2">
              <button type="button" className={`w-1/2 h-12 ${kycRequired ? "bg-navy-gradient text-white" : "bg-gray-700 text-gray-400"}`} onClick={() => setKycRequired(true)}>Yes</button>
              <button type="button" className={`w-1/2 h-12 ${!kycRequired ? "bg-navy-gradient text-white" : "bg-gray-700 text-gray-400"}`} onClick={() => setKycRequired(false)}>No</button>
            </div>
          </div>

          {/* Referral Code Input */}
          <div className="mb-4">
            <label className="text-gray-400 font-semibold">Referral Code: </label>
            <input
              type="text"
              value={referredBy}
              onChange={(e) => setReferredBy(e.target.value)}
              className="text-white rounded-lg bg-gray-700 mt-2 p-2 pl-4 pr-8 focus:border-blue-500 focus:bg-gray-800 focus:outline-none w-full"
            />
          </div>
          {/* Submit Button */}
          <button type="submit" disabled={isButtonDisabled} className="w-full my-5 py-2 bg-navy-gradient text-white font-semibold rounded-lg">
            {buttonText}
          </button>
          </form>
      ) : (
        <div className="max-w-[400px] w-full mx-auto rounded-lg bg-gray-900 p-8 px-8">
          <h2 className="text-4xl text-white font-bold text-center">Thank You for Waitlisting!</h2>
          <p className="text-white text-center my-4">
          Your referral code is: <span style={{ color: 'orange' }}>{referralCode}</span>
          </p>          <p className="text-white text-center my-4">If you have any input or feedback, feel free to email us at info@payment3.network. If you have friends that would be interested in making payments in crypto, feel free to share with them as well.</p>
          <button onClick={() => navigate('/')} className="w-full my-5 py-2 bg-navy-gradient text-white font-semibold rounded-lg">
            Return to Homepage
          </button>
        </div>
    )}
</div>
</div>
      <div className={`bg-main-bg ${styles.paddingX}`}>
        <Footer />
      </div>
      </div>
  );
};

export default WaitlistForm;
